<template>
  <div>
    <v-row>
      <v-col cols="12">
        <input-qr-scan-model
          v-model="code"
          :label="placeholder"
          @keyupEnter="inputScan"
        ></input-qr-scan-model>
      </v-col>

      <v-col
        cols="12"
        class="text-center"
        v-if="currentPickingItem && currentPickingItem.goods_sku"
      >
        <div class="pb-2">
          Sọt {{ currentPickingItem.basket_index }}:
          {{ currentPickingItem.goods_sku }} -
          {{ currentPickingItem.goods_barcode }}
          <!--x {{ currentPickingItem.quantity }}-->
        </div>
        <v-alert color="error" text dark class="text-h2 mb-0">
          {{ currentPickingItem.cell_id }}
          <div
            v-if="goods.comment_pickup"
            style="font-size: 15px; line-height: 19px"
            class="font-weight-medium font-italic black--text"
          >
            {{ goods.comment_pickup }}
          </div>
        </v-alert>
        <v-alert color="green" text dark class="text-h2 box-quantity mb-0">
          <div class="sum-by-cell error--text">{{ sumByCell }}</div>
          {{ pickQuantity }}
        </v-alert>
        <v-alert color="secondary" text dark class="text-h4">
          <span class="text-uppercase">{{ $t("labels.basket") }}: </span>
          {{ currentPickingItem.basket_index }} [{{
            currentPickingItem.basket_code
          }}]
        </v-alert>
        <!--<div class="pt-2" v-if="prevPickingItem && prevPickingItem.id">
          {{ $t('labels.previous_command') }}: {{ $t('labels.basket') }}
          {{ prevPickingItem.basket_index }}: {{ prevPickingItem.sku }} x {{ prevPickingItem.quantity }} - {{ prevPickingItem.cell_id }}
        </div>-->
        <v-row>
          <v-col v-if="selected.process_type === 1" cols="6">
            <v-btn block color="error" @click="cancelPickupType1">
              {{ $t("labels.stop_pickup") }}
            </v-btn>
          </v-col>
          <v-col :cols="selected.process_type === 1 ? 6 : 12">
            <v-btn block color="warning" @click="showDialogOutOfStock">
              Vị trí hết hàng
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else-if="!isLoading" class="text-center text-h6 error--text">
        {{ $t("messages.error_pickup_no_position") }}
      </v-col>

      <v-col cols="12">
        <div class="text-center">{{ goods.name }}</div>
        <div class="d-flex align-center justify-center" v-if="goods.url_images">
          <ImageViewer width="300px" height="auto" :url="goods.url_images" />
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="prevPickingItem && prevPickingItem.id_basket"
        class="text-center"
      >
        {{ $t("labels.previous_command") }}:
        <span class="error--text">
          {{ prevPickingItem.picked }} &times; {{ prevPickingItem.goods_sku }} -
          {{ prevPickingItem.goods_barcode }}
        </span>
        - {{ $t("labels.basket") }}:
        <span class="error--text">
          {{ prevPickingItem.basket_index }}
        </span>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogOutOfStock" persistent max-width="400px">
      <PickupOutOfStock
        v-if="dialogOutOfStock"
        :item="currentPickingItem"
        @cancel="hideDialogOutOfStock"
        @confirm="confirmOutOfStock"
      />
    </v-dialog>

    <v-dialog v-model="dialogMissingStock" persistent max-width="400px">
      <PickupMissingStock
        v-if="dialogMissingStock"
        :item="currentPickingItem"
        @cancel="hideDialogMissingStock"
        @confirm="confirmMissingStock"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  BARCODE_PICKUP_LATER,
  GOODS_UID_ACTION_TYPE_PICKUP,
} from "@/libs/const";
import _ from "lodash";

export default {
  name: "PickupStep4",
  components: {
    PickupOutOfStock: () =>
      import("@/components/order_online/PickupOutOfStock"),
    PickupMissingStock: () =>
      import("@/components/order_online/PickupMissingStock"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detailCommand: [],
    isLoading: false,
    code: null,
    items: [],
    currentPickingItem: {},
    prevPickingItem: {},
    currentPickingItemIndex: null,
    step: 1,
    dialogOutOfStock: false,
    dialogMissingStock: false,
    goods: {},
  }),
  computed: {
    isShowRollCell() {
      if (!this.currentPickingItem) {
        return false;
      }
      const sameGoods = this.items.filter(
        (i) =>
          i.id_goods === this.currentPickingItem.id_goods &&
          i.cell_id !== this.currentPickingItem.cell_id &&
          !i.status &&
          this.currentPickingItem.goods_expired_date === i.goods_expired_date
      );
      return sameGoods.length > 0;
    },
    placeholder() {
      return this.$t("labels.uid");
    },
    pickQuantity() {
      // const sub1 = +this.currentPickingItem.quantity - +this.currentPickingItem.picked
      // const sub2 = this.currentPickingItem.basket_cell_quantity - this.currentPickingItem.basket_cell_picked
      return this.currentPickingItem.remaining_quantity >
        this.currentPickingItem.location_quantity
        ? this.currentPickingItem.location_quantity
        : this.currentPickingItem.remaining_quantity;
    },
    sumByCell() {
      let sum = 0;
      const checkedItems = [...this.items].filter(
        (c) =>
          c.id_goods === this.currentPickingItem.id_goods &&
          c.id_pos_location === this.currentPickingItem.id_pos_location &&
          !c.status
      );
      checkedItems.forEach((i) => {
        sum += +i.remaining_quantity;
      });
      return sum > this.currentPickingItem.location_quantity
        ? this.currentPickingItem.location_quantity
        : sum;
    },
    currentGoodsId() {
      return (
        (this.currentPickingItem && this.currentPickingItem.id_goods) || null
      );
    },
  },
  watch: {
    currentGoodsId() {
      this.getGoodsData();
    },
  },
  methods: {
    async inputScan() {
      if (this.code && this.code.toUpperCase() === BARCODE_PICKUP_LATER) {
        this.code = null;
        await this.pickupLater();
        return false;
      }

      let uid = {};
      try {
        const { data } = await httpClient.post("/get-item", {
          code: this.code,
          id_pos: this.selected.id_pos,
          action_type: GOODS_UID_ACTION_TYPE_PICKUP,
        });
        if (this.currentPickingItem.id_goods != data.id_goods) {
          this.$root.$emit("playErrorAudio");
          this.$vToastify.error(
            this.$t("messages.wrong_code", { code: this.code })
          );
          this.code = null;
          return false;
        }
        uid = { ...data };
      } catch (e) {
        this.code = null;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/order-online-pickup-picked", {
          id_pickup_command: this.selected.id_command,
          id_goods: this.currentPickingItem.id_goods,
          id_basket: this.currentPickingItem.id_basket,
          id_pos_location: this.currentPickingItem.id_pos_location,
          id_goods_special_barcode: uid.id,
        });
        if (!data || data.length === 0) {
          this.$emit("onUpdate", {
            ...this.filters,
            process_type: null,
            pickup_type: null,
            order_ids: [],
            scannedOrders: [],
            id_command: null,
          });
          return false;
        }
        this.detailCommand = [...data];
        this.isLoading = false;
        this.code = null;
        this.prevPickingItem = { ...this.currentPickingItem };
        this.currentPickingItemIndex = null;
        this.sortLocations();
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async getDetailCommand() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/order-online-pickup-get-detail-command",
          {
            id_pickup_command: this.selected.id_command,
          }
        );
        this.detailCommand = [...data];
        this.isLoading = false;
        this.sortLocations();
      } catch (e) {
        this.isLoading = false;
      }
    },

    getZoneToNumber(zone) {
      return zone ? zone.charCodeAt(0) : 0;
    },

    sortLocations() {
      let locations = [...this.detailCommand];
      const turn = (locations[0] && locations[0].turn) || 0;
      const isEvenTurn = turn % 2 === 0;

      // 0. Lấy tất cả vị trí có liên kết
      let tempPositions = _.chain(locations)
        .map((l) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = parseInt(l.position);
          const bff = l.bff
            ? zone + parseInt(l.bff)
            : zone + parseInt(position);
          return [position, bff];
        })
        .uniq()
        .value();
      let positions = [];
      _.each(tempPositions, (tp) => {
        let pExisted = _.find(positions, (p) => {
          return (
            (p[0] === tp[0] && p[1] === tp[1]) ||
            (p[0] === tp[1] && p[1] === tp[0])
          );
        });
        if (!pExisted) {
          positions.push(tp);
        }
      });

      // 1. Chuyển tất cả vị trí có liên kết dãy về 1 dãy ảo
      let virtualPositions = [];
      _.each(locations, (l) => {
        let bffPosition = _.find(positions, (p) => {
          return parseInt(l.position) === p[0] || parseInt(l.position) === p[1];
        });
        l.virtualPosition = Math.min(bffPosition[0], bffPosition[1]);
        if (virtualPositions.indexOf(l.virtualPosition) < 0) {
          virtualPositions.push(l.virtualPosition);
        }
      });

      // 2. Nhóm theo 1 dãy mới
      let locationGroups = _.groupBy(locations, "virtualPosition");
      virtualPositions.sort((a, b) => {
        return isEvenTurn ? a - b : b - a;
      });

      // 3. Sort từng dãy
      let orderAsc = isEvenTurn;
      let result = [];
      for (let vp of virtualPositions) {
        let vpLocations = locationGroups[`${vp}`];
        let newVpLocations = _.orderBy(
          vpLocations,
          ["indexing", "quantity"],
          [orderAsc ? "asc" : "desc", "asc"]
        );
        _.each(newVpLocations, (nvl) => {
          result.push(nvl);
        });
        orderAsc = !orderAsc;
      }

      this.items = [...result];
      this.nextStowingItem();
    },

    nextStowingItem() {
      if (this.currentPickingItemIndex == null) {
        this.currentPickingItemIndex = 0;
      } else {
        this.currentPickingItemIndex =
          this.currentPickingItemIndex == this.items.length - 1
            ? null
            : this.currentPickingItemIndex + 1;
      }

      if (this.currentPickingItemIndex != null) {
        if (this.items[this.currentPickingItemIndex].status) {
          this.nextStowingItem();
        } else {
          this.currentPickingItem = {
            ...this.items[this.currentPickingItemIndex],
          };
        }
      }
    },

    showDialogOutOfStock() {
      this.dialogOutOfStock = true;
    },
    hideDialogOutOfStock() {
      this.dialogOutOfStock = false;
    },
    confirmOutOfStock(quantity) {
      this.currentPickingItem = {
        ...this.currentPickingItem,
        is_out_of_stock: true,
        out_of_stock_quantity: quantity,
      };
      this.inputScan();
      this.hideDialogOutOfStock();
    },

    showDialogMissingStock() {
      this.dialogMissingStock = true;
    },
    hideDialogMissingStock() {
      this.dialogMissingStock = false;
    },
    confirmMissingStock(quantity) {
      this.currentPickingItem = {
        ...this.currentPickingItem,
        is_missing_stock: true,
        missing_quantity: quantity,
      };
      this.inputScan();
      this.hideDialogMissingStock();
    },

    async getGoodsData() {
      if (!this.currentGoodsId) {
        this.goods = {};
        return false;
      }
      const { data } = await httpClient.post("/goods-detail", {
        id: this.currentGoodsId,
      });
      this.goods = { ...data };
    },

    async cancelPickupType1() {
      if (!confirm(this.$t("messages.stop_pickup_type_1"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/order-online-pickup-cancel-type-1", {
          id_pickup_command: this.selected.id_command,
          id_basket: this.currentPickingItem.id_basket,
        });
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.$emit("onUpdate", {
          ...this.filters,
          process_type: null,
          pickup_type: null,
          order_ids: [],
          scannedOrders: [],
          id_command: null,
        });
      } catch (e) {
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async pickupLater() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/order-online-pickup-later", {
          id_pickup_command: this.selected.id_command,
          id_pos_location: this.currentPickingItem.id_pos_location,
        });
        this.isLoading = false;
        this.currentPickingItemIndex = null;
        this.detailCommand = [...data];
        this.isLoading = false;
        this.sortLocations();
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    this.getDetailCommand();
  },
};
</script>

<style lang="scss">
.box-quantity {
  position: relative;

  .sum-by-cell {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
